@import '../../scss/utilities';

.art {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.art__image {
  width: 175px;
  height: 183px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  @each $category, $color in $category-colors {
    &--#{$category}-even {
      background-image: url('/images/categories/#{$category}/spot-#{$category}-even-175.png');
    }
    &--#{$category}-odd {
      background-image: url('/images/categories/#{$category}/spot-#{$category}-odd-175.png');
    }
  }
}
